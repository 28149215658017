/** Request 网络请求工具 更详细的 api 文档: https://github.com/umijs/umi-request */
import React from 'react';
import { extend } from 'umi-request';
import { Button, notification } from 'antd';

const codeMessage = {
  200: '服务器成功返回请求的数据。',
  201: '新建或修改数据成功。',
  202: '一个请求已经进入后台排队（异步任务）。',
  204: '删除数据成功。',
  400: '发出的请求有错误，服务器没有进行新建或修改数据的操作。',
  401: '用户没有权限（令牌、用户名、密码错误）。',
  403: '用户得到授权，但是访问是被禁止的。',
  404: '发出的请求针对的是不存在的记录，服务器没有进行操作。',
  406: '请求的格式不可得。',
  410: '请求的资源被永久删除，且不会再得到的。',
  422: '当新建一个对象时，发生一个验证错误。',
  500: '服务器发生错误，请检查服务器。',
  502: '网关错误。',
  503: '服务不可用，服务器暂时过载或维护。',
  504: '网关超时。',
};

const customCodeMessage = {
  4018: '该项目未配置代码仓库或该系列芯片暂未配置工程框架，请联系管理员解决。',
  4021: '该项目所使用的 FL 驱动库版本和您配置的代码仓库中所使用的 FL 驱动库版本不匹配，请确认后再试。',
  4034: '抱歉，您选择的芯片例程和芯片封装不兼容，我们将在后续版本中逐渐完善该功能。',
};
/** 异常处理程序 */

// 返回一个 AbortSignal 对象实例，它可以用来 with/abort 一个 DOM 请求。
const controller = new AbortController();
const { signal } = controller;

const btn = (
  <>
    <Button
      key="back"
      size="small"
      onClick={() => {
        window.location.href = '/';
      }}
    >
      返回主页
    </Button>
    <Button
      key="login"
      type="primary"
      size="small"
      onClick={() => {
        let SSO_CENTER;
        window.location.href = `${SSO_CENTER}/auth_box?redirect=${window.location.href}`;
      }}
      style={{ marginLeft: '15px' }}
    >
      重新登录
    </Button>
  </>
);

const errorHandler = (error) => {
  const { response, request } = error;
  const { url } = request;
  if (response && response.status) {
    // 401 请求跳转 sso
    if (response.status === 401) {
      // setToken(null);
      notification.error({
        key: 'UNAUTHORIZED',
        message: `令牌过期`,
        description: '您的登录令牌已过期，请重新登录后再试！',
        btn,
        duration: 0,
      });
      return;
    }

    // http status = 200 但是 data.code !== 200，认为是一个操作错误
    if (response.status === 200 && response.data.code !== 200) {
      if (!(response.data.code >= 5000 && response.data.code <= 5999)) {
        notification.error({
          key: url,
          message: `操作失败`,
          description: customCodeMessage[response.data.code]
            ? customCodeMessage[response.data.code]
            : response.data.message,
          duration: 10,
        });
      } else {
        throw response;
      }
    } else {
      notification.error({
        key: response.status,
        message: `请求异常 代码 ${response.status}`,
        description: codeMessage[response.status],
      });
    }
  } else if (!response) {
    notification.error({
      key: 'NETWORK_ERROR',
      description: '您的网络发生异常，无法连接服务器',
      message: '网络异常',
    });
  }

  throw response;
};

let API_SERVER = "http://registerapi.fmdevelopers.com.cn";
/** 配置request请求时的默认参数 */
const request = extend({
  prefix: API_SERVER,
  timeout: 30000,
  errorHandler,
  signal,
});

/** 配置request全局请求拦截器 */
request.interceptors.request.use((url, options) => {
  const headers = {};
  // const token = getToken();
  // if (token) {
  //   headers.Authorization = `Bearer ${token}`;
  // }
  return {
    url,
    options: { ...options, headers },
  };
});

/** 克隆响应对象做解析处理，将 Data 从 response 中解构出来 */
request.interceptors.response.use(async (response, options) => {
  // 1. 单独处理 responseType == blob 类型响应
  if (options.responseType === 'blob') {
    return response;
  }
  // 2. 单独处理 401 和 403 状态响应
  if (response.status === 401 || response.status === 403) {
    controller.abort();
    return response;
  }
  // 3. 其他 http status 则认为是正常请求，并解析出数据
  const res = await response.clone().json();
  const { code, message, data } = res;
  // 4. 如果 data.code != 200，则认为用户操作异常，需要从 data 中解析出错误信息，并抛出
  if (code !== 200) {
    response.data = {
      code,
      message,
      data,
    };
    // eslint-disable-next-line no-throw-literal
    throw { response };
  }
  // 5. 一切正常，解析出响应数据，并返回
  return new Promise((resolve) => {
    resolve(res);
  });
});

export default request;
