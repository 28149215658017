import React from 'react';
import logo from './logo.svg';
import './App.css';
import RegisterView from './components/register'
// @ts-ignore
import { Helmet } from 'react-helmet';


function App() {
  return (
      <>
          <Helmet>
              <title>MeterGo/OOPT 注册</title>
          </Helmet>
          <div className="App">
              <RegisterView />
          </div>
      </>

  );
}

export default App;
