import React, {Component} from 'react';
// import { Clipboard } from '@react-native-community/clipboard'
import { Layout, message, Input, Tooltip, Button, Card } from 'antd'
import 'antd/dist/antd.css'
import './css/peripheral.css'

// import copy from 'copy-to-clipboard';
import { CopyOutlined, EditOutlined } from '@ant-design/icons';
import request from "./utils/request";


const { Content} = Layout;




class MainView extends Component {


    state = {
        reqCode:"",
        resCode: "",
        password: ""
    }


    async getRegister(reqCode, password) {
        return request(`/register/metergo`, {
            method: 'post',
            data: { reqCode: reqCode, password: password},
        });
    }

    handleRegister() {
        this.getRegister(this.state.reqCode, this.state.password).then((res)=>{
            if (res.result === true){
                this.setState({
                    resCode: res.data
                })
                message.success("注册成功")
            }else {
                message.error(res.message)
            }
        }).catch((err)=>{
            message.error(err).then(r => {})
        })
    }

    handleInputChange(e) {
        this.setState({
            reqCode: e.target.value,
            resCode: ''
        })
    }

    handleInputPassword(e) {
        this.setState({
            password: e.target.value,
        })
    }


    async pasteValue(){
        if (navigator.clipboard && window.isSecureContext) {
            // 安全模式
            navigator.clipboard.readText().then(text => {
                text = text.trim()
                this.setState({
                    reqCode: text,
                    resCode: ''
                })
            }).catch(err => {
                message.info('没有找到申请码')
            });
        } else {
        //    非安全模式

        }


    }
    //
    // copyToInputBox2(){
    //
    //     let clipString = Clipboard.getString()
    //     this.setState({
    //         reqCode: clipString,
    //         resCode: ''
    //     })
    //     message.success("已粘贴")
    //
    //
    // }

    copyToInputBox(){
        navigator.clipboard.readText().then(text => {
            text = text.trim()
            this.setState({
                reqCode: text,
                resCode: ''
            })
        }).catch(err => {
                message.info('没有找到申请码')
            });
    }

    // async copyValue(val) {
    //     if (navigator.clipboard && window.isSecureContext) {
    //         // navigator clipboard 向剪贴板写文本
    //         message.success('复制成功')
    //         return navigator.clipboard.writeText(val)
    //     } else {
    //         // 创建text area
    //         const textArea = document.createElement('textarea')
    //         textArea.value = val
    //         // 使text area不在viewport，同时设置不可见
    //         document.body.appendChild(textArea)
    //         textArea.focus()
    //         textArea.select()
    //         this.message.success('复制成功')
    //         return new Promise((res, rej) => {
    //             // 执行复制命令并移除文本框
    //             document.execCommand('copy') ? res() : rej()
    //             textArea.remove()
    //         })
    //     }
    // }

    async copyValue2(val) {
        // copy(val)
        message.success("已复制")
    }

    copyToClipboard(){

        // this.copyValue(this.state.reqCode)
        // copy(this.state.reqCode)
        // message.success("已复制1")
        navigator.clipboard.writeText(this.state.resCode).then(r => {
            message.info("复制成功")
        });
    }


    render() {
        return (
            <Layout className="main-view">
                    {/*<Header className="header" style={{height: '50px', backgroundColor: "#292421", color: "white", fontSize: '15'}}>*/}
                    {/*        MeterGo / OOPT 注册工具*/}
                    {/*</Header>*/}
                    <Layout>
                        <Content>
                            <br/>
                            <Card
                                title="MeterGo / OOPT 注册"
                                bordered={false}
                                style={{
                                    width: 850,
                                    margin: '0px auto'
                                }}
                            >
                                <div style={{width: '800px', height: '300px', margin: '0px auto' }}>
                                    <br/>

                                    <Input.Group compact>
                                        <Input.Password
                                            addonBefore="用户密码："
                                            style={{
                                                width: 'calc(100% - 200px)',
                                            }}
                                            placeholder="输入密码"
                                            onChange={(e)=>this.handleInputPassword(e)}
                                        />
                                        <Tooltip title="需要密码验证你是授权的用户">
                                            <Button icon={<EditOutlined />}/>
                                        </Tooltip>
                                    </Input.Group>
                                    <br/>
                                    <Input.Group compact>
                                        <Input
                                            addonBefore="申请码："
                                            style={{
                                                width: 'calc(100% - 200px)',
                                            }}
                                            placeholder="请贴入申请码"
                                            value={this.state.reqCode}
                                            onChange={(e)=>this.handleInputChange(e)}
                                        />
                                        <Tooltip title="粘贴申请码">
                                            <Button icon={<EditOutlined />}  onClick={()=>this.copyToInputBox()}/>
                                        </Tooltip>
                                    </Input.Group>
                                    <br/>
                                    <Input.Group compact>
                                        <Input
                                            id="vcode"
                                            addonBefore="注册码："
                                            readOnly={true}
                                            style={{
                                                width: 'calc(100% - 200px)',
                                            }}
                                            placeholder=""
                                            value={this.state.resCode}
                                        />
                                        <Tooltip title="复制注册码">
                                            <Button icon={<CopyOutlined />} onClick={()=>this.copyToClipboard()}/>
                                        </Tooltip>
                                    </Input.Group>
                                    <br/>
                                    <Button style={{margin: '10px'}} type="primary" onClick={()=>this.handleRegister()} >提交注册</Button>
                                    <Button style={{margin: '10px'}} type="primary" onClick={()=>this.copyToClipboard()} >复制注册码</Button>
                                </div>

                            </Card>


                        </Content>
                    </Layout>
            </Layout>
        );
    }
}

export default MainView;